import React from "react";
import { withI18n } from "react-i18next";
import styled, { css } from "styled-components";
import { connect } from "react-redux";

import { phoneValidation } from "../../../helpers/validations";
import PhoneInput from "react-phone-input-2";
import Footer from "../../Common/Footer";
import Modal from "../../Common/Modal";
import PageContainer from "../../Common/PageContainer";
import {
  Section,
  StyledBlueBtn,
  SectionTitle,
  SmallTextError,
  Spinner,
} from "../../Common/StyledComponents";
import { iRootState } from "../../../store";
import { IActorCredentials, IActorError, IActorPhone } from "../../../shared/api/dtos/IActorDto";
import { IGroupedCountry } from "../../../helpers/ICountryList";
import { getCountryCode, getCurUserSignUp, getSessionId } from "../../../helpers";
import { Typography } from "components/Common";
import qs from "querystring";
import { IBaseSignupCredentials } from "shared/api/dtos/ISignupDto";

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
}

interface IState {
  isFormSubmitClicked: boolean;
  fields: IActorPhone;
  customError?: string;
}

class ActorCreatePhoneStep extends React.Component<IProps, IState> {
  public state: IState = {
    isFormSubmitClicked: false,
    fields: { phone: "" },
  };

  async componentDidMount() {
    const sessionId = getSessionId();
    if (!sessionId) {
      // window.location.replace(
      //   process.env.REACT_APP_CWB_500
      // );
    }

    if (!this.props.countryCode && !getCountryCode()) {

      const qsArgs = this.props.location.search ? { ...qs.parse(this.props.location.search) } as any : {};
      if (qsArgs.dev && qsArgs.ctry) {
        await this.props.setForceRequesterCountryCode(qsArgs.ctry.toString())
      } else {
        try {
          await this.props.setRequesterCountryCode();
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  checkValidation() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;
    if (!fields.phone) {
      errors.Phone = t("Invalid Phone Number");
    } else if (phoneValidation(fields.phone, t))
      errors.Phone = phoneValidation(fields.phone, t);

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  checkNumber() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;

    if (!fields.phone || fields.phone.match(/^[0-9`]+$/i)) {
      this.props.setErrors(errors);
      return true;
    }

    errors.Phone = t("Only numbers are accepted");
    fields.phone = "";
    this.setState({ fields });
    this.props.setErrors(errors);
    return false;
  }

  handleChange = (phoneNumber: string) => {
    this.setState({
      fields: {
        ...this.state.fields,
        phone: phoneNumber,
      },
    });
    this.checkNumber();
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  getNumber = (): string => {
    return `+${this.state.fields.phone}`;
  };

  handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      this.createAccount(event);
    }
  };

  createAccount = async (e: any) => {
    e.preventDefault();
    if (this.props.isLoading) return;
    this.setState({ ...this.state, customError: '', isFormSubmitClicked: true });
    if (!this.checkValidation()) return;
    await this.props.createActorAccount({
      phone: this.getNumber(),
      ...this.props.actorCredentials,
    });
    this.moveToNext();
  };

  moveToNext = () => {
    if (this.props.errors.errorMessage) {
      this.setState({ ...this.state, customError: this.props.errors.errorMessage })
    } else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/2",
        search: this.props.location.search,
      });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  changeVerifyMethod = () => {
    this.props.setSelectPhone(false);
  };

  render() {
    const { t, countryCode } = this.props;
    const userCountryCode = countryCode || getCountryCode();
    return (
      <PageContainer>
        <Wrapper>
          <Title>{t("Verify with phone number")}</Title>{/* i18n-todo */}
          <SubTitle>
            {t(
              "Input your phone number and we will send you a code"
            )}
          </SubTitle>
          <form
            name="registerForm"
            className="registerForm"
            onSubmit={this.createAccount}
            style={{ width: "100%" }}
          >
            <fieldset>
              <Section>
                {/* <SectionTitle>{t("Phone Number")}</SectionTitle> */}
                <PhoneInput
                  containerStyle={{ marginTop: "8px" }}
                  country={userCountryCode ? userCountryCode.toLocaleLowerCase() : ""}
                  value={this.state.fields.phone}
                  onChange={(phoneNumber) => this.handleChange(phoneNumber)}
                  onEnterKeyPress={this.handleKeyPress}
                  inputProps={{
                    autoFocus: true,
                  }}
                />
                <SmallTextError className="error">
                  <span>{this.props.errors.Phone}</span>
                </SmallTextError>

                {this.state.customError && (
                  <Typography color="error" variant="captionBold">
                    {this.state.customError}
                  </Typography>
                )}
              </Section>
              {/* <InfoBox>
                {t(
                  "We only use this to verify your account. We’ll never contact you without your permission."
                )}
              </InfoBox> */}
              <Section>
                <StyledBlueBtn type="submit" id="submit" disabled={this.props.isLoading}>
                  {t("Send Code")} {/* i18n-todo */}
                  {this.props.isLoading && (
                    <Spinner
                      src="../images/spinner.svg"
                      className="spinner-width"
                    />
                  )}
                </StyledBlueBtn>
              </Section>
            </fieldset>
          </form>
          {/* <StyledAnchor onClick={this.changeVerifyMethod}>
            {t("Verify By Email")}
          </StyledAnchor> */}
        </Wrapper>
        <Footer />
      </PageContainer>
    );
  }
}

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-weight: ${(p) => p.theme["font-weight-600"]};

  max-width: 480px;
  min-width: 480px;
  background-color: ${(p) => p.theme.white};
  padding: 40px 80px;

  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 40px 30px;
  }

  * {
    font-size: ${(p) => p.theme["s-font-size"]};
  }

  & div.flag-dropdown {
    background-color: #ffffff;
  }
`;

const Title = styled.div`
  font-size: ${(p) => p.theme["xxl-font-size"]} !important;
  text-align: center;
`;

const SubTitle = styled.div`
  color: ${(p) => p.theme.color} !important;
  margin: auto 8px;
  font-weight: normal;
  text-align: center;
`;

const InfoBox = styled.div`
  color: ${(p) => p.theme.color} !important;
  font-weight: normal;
  border-radius: 4px;
  background-color: #f8f8f9;
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 16px;
  text-align: center;
`;

const StyledAnchor = styled.a<{ disabled?: boolean }>`
  && {
    color: #00a2e0;
    font-size: ${(p) => p.theme["xs-font-size"]};
    font-weight: ${(p) => p.theme["font-weight-600"]};
    letter-spacing: 0.21px;
    line-height: 15px;
    text-align: center;
    margin-top: 8px;
    cursor: pointer;

    &:hover {
      color: ${(p) => p.theme.lightBlue};
    }

    ${(p) =>
    p.disabled &&
    css`
        color: ${(p) => p.theme.typography.color.disabled};
        cursor: default;

        &:hover {
          color: ${(p) => p.theme.typography.color.disabled};
        }
      `}
  }
`;

interface StateProps {
  errors: IActorError;
  fields: IActorPhone;
  groupCountryList: IGroupedCountry[];
  isLoading: boolean;
  countryCode: string;
  actorCredentials: IBaseSignupCredentials;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.actorModel.errors,
    fields: state.actorModel.fields,
    groupCountryList: state.referencesModel.groupCountryList,
    isLoading: state.actorModel.isLoading,
    countryCode: state.referencesModel.countryCode,
    actorCredentials: state.actorModel.actorCredentials,
  };
}

interface DispatchProps {
  setErrors: (errors: IActorError) => void;
  createActorAccount: (dto: IBaseSignupCredentials) => void;
  getGroupCountryList: () => void;
  setSelectPhone: (phoneSelected: boolean) => void;
  setRequesterCountryCode: (fallback?: string | null) => void;
  setForceRequesterCountryCode: (country: string) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    setErrors: dispatch.actorModel.setErrors,
    createActorAccount: dispatch.actorModel.createActorAccount,
    getGroupCountryList: dispatch.referencesModel.getGroupCountryList,
    setSelectPhone: dispatch.actorModel.setSelectPhone,
    setRequesterCountryCode: dispatch.referencesModel.setRequesterCountryCode,
    setForceRequesterCountryCode: dispatch.referencesModel.setForceRequesterCountryCode,
  };
}

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorCreatePhoneStep)
);
