import React from "react";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import qs from 'query-string';

import { emailValidation, passwordValidation } from "../../../helpers/validations";
import {
  Section,
  SectionTitle,
  StyledInput,
  StyledBlueBtn,
  SmallText,
  Spinner
} from "../../Common/StyledComponents";
import PasswordInput from "../../Common/PasswordInput";
import Typography from "../../Common/Typography";
import { iRootState } from "../../../store";
import {
  IActorCredentials,
  IActorError
} from "../../../shared/api/dtos/IActorDto";
import TermUsePrivacy from "../../Common/TermUsePrivacy";
import { PasswordLength, setCurUserSignUp } from "../../../helpers";

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
  countryCode: string
}

interface IState {
  fields: IActorCredentials;
  errors: IActorError;
  isFormSubmitClicked: boolean;
}

class ActorCredentialStep extends React.Component<IProps, IState> {
  public state: IState = {
    fields: {
      email: "",
      password: ""
    } as IActorCredentials,
    errors: this.props.errors || ({} as IActorError),
    isFormSubmitClicked: false
  };

  async componentWillReceiveProps(nextProps: IProps, prevProps: IProps) {
    if (nextProps.errors !== prevProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  checkValidation() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors: IActorError = {} as IActorError;

    if (emailValidation(fields.email, t))
      errors.Email = emailValidation(fields.email, t);
  
    if (fields.email !== fields.confirmEmail)
      errors.ConfirmEmail = t("Email and Confirm Email don't match");  /* i18n-todo */

    if (passwordValidation(fields.password, PasswordLength, t))
      errors.Password = passwordValidation(fields.password, PasswordLength, t);

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  handleChange = (e: any) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  createAccount = async (e: any) => {
    e.preventDefault();
    this.setState({ isFormSubmitClicked: true });
    let fields = this.state.fields;
    if (!this.checkValidation()) return;
    this.props.setActorCredentials(fields);
    // await this.props.createActorAccount(fields);
    this.moveToNext();
  };

  moveToNext = () => {
    /* if (this.props.errors.errorMessage)
      window.location.href = (`${process.env.REACT_APP_CWB_500}`);
    else  */if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      if (this.props.countryCode?.toUpperCase() === 'US' && new Date() < new Date('2022-11-03')) {
        const params = qs.stringify({
           special: true,
          ...(qs.parse(this.props.location.search))
        });
        this.props.history.push(`/actor/1?${params}`);
      } else {
        this.props.history.push({
          pathname: "/actor/1",
          search: this.props.location.search
        });
      }
    }
  };

  render() {
    const { t, countryCode } = this.props;
    const errors = this.state.errors;
    const fields = this.state.fields;

    return (
      <Wrapper>
        {
          countryCode?.toUpperCase() === "US"
            && new Date() < new Date('2022-11-03') ? <>
            <TrialTypography color="darkGrey"
              component="h2"
              gutterBottom
              variant="h3">
              {t("Sign-up for our Free Trial until Nov 2, 2022")}
            </TrialTypography>
            <Typography style={{ fontSize: 13 }}>
              {t("New to Casting Workbook?")}
              <a href={t("https://home.castingworkbook.com/for-actors/")} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 8 }} >
                {t("Click here to find out more")}</a>
            </Typography>
          </> : <>
            {/* <StyledTypography>
              {t("Take your career to the next level with opportunities from casting directors around the world.")}
            </StyledTypography>
            <PayTypography
              id="get-started"
              color="darkGrey"
              component="h3"
              align="center"
              gutterBottom
              variant="h3"
            >
              {t("Get Started for Free")}
            </PayTypography> */}
          </>
        }
        <form
          name="registerForm"
          className="registerForm"
          onSubmit={this.createAccount}
          style={{ width: "100%" }}
        >
          <fieldset>
            <Section>
              <SectionTitle>{t("Email address")}</SectionTitle>
              <StyledInput
                name="email"
                type="text"
                autoComplete="off"
                className={errors.Email ? "invalid" : ""}
                onChange={this.handleChange}
                value={fields.email}
              />
              {/* TODO: SmallText vs SmalTextError */}
              <SmallText className="error">
                <span>{errors.Email}</span>
              </SmallText>
            </Section>
            <Section>
              <SectionTitle>{t("Confirm email address")}</SectionTitle>
              <StyledInput
                name="confirmEmail"
                type="text"
                autoComplete="off"
                className={errors.ConfirmEmail ? "invalid" : ""}
                onChange={this.handleChange}
                value={fields.confirmEmail}
              />
              {/* TODO: SmallText vs SmalTextError */}
              <SmallText className="error">
                <span>{errors.ConfirmEmail}</span>
              </SmallText>
            </Section>
            <Section>
              <SectionTitle>{t("Password")}</SectionTitle>
              <PasswordInput
                name="password"
                autoComplete="new-password"
                className={errors.Password ? "invalid" : ""}
                onChange={this.handleChange}
                value={fields.password}
              />
              {!errors.Password && (
                <SmallText>
                  <span>{t("Must contain at least 6 characters, 1 letter and 1 number")}</span>
                </SmallText>
              )}
              <SmallText className="error">
                <span>{errors.Password}</span>
              </SmallText>
            </Section>
            <Section>
              <StyledBlueBtn type="submit" id="submit" disabled={this.props.isLoading}>
                {t("Create Account")}
                {this.props.isLoading && (
                  <Spinner
                    src="../images/spinner.svg"
                    className="spinner-width"
                  />
                )}
              </StyledBlueBtn>
            </Section>
            <Typography style={{ fontSize: 13 }}>
              {t("*No credit card necessary to apply")}
            </Typography>
          </fieldset>
        </form>
        <TermUsePrivacy />
      </Wrapper>
    );
  }
}

interface StateProps {
  errors: IActorError;
  isLoading: boolean;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.actorModel.errors,
    isLoading: state.actorModel.isLoading
  };
}

interface DispatchProps {
  createActorAccount: (fields: IActorCredentials) => void;
  setErrors: (errors: IActorError) => void;
  setActorCredentials: (actorCredentials: any) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    createActorAccount: dispatch.actorModel.createActorAccount,
    setErrors: dispatch.actorModel.setErrors,
    setActorCredentials: dispatch.actorModel.setActorCredentials,
  };
}

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorCredentialStep)
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  * {
    width: 100%;
  }

  & input {
    font-size: ${p => p.theme["s-font-size"]};
  }
`;

// const StyledTypography = styled(Typography)`
//   margin: ${p => p.theme.spacing(3, 0, 3.5)};
//   font-size: 13px;
//   line-height: 18px;
// `;

// const PayTypography = styled(Typography)`
//   font-size: 17px;
// `;

const TrialTypography = styled(Typography)`
  font-size: 16px;
  margin-top: ${p => p.theme.spacing(3)};
`;

// const DetailLink = styled.a`
//   && {
//     color: #00AAFF;
//     font-size: 13px;
//     font-weight: normal;
//     text-decoration: underline;

//     &:hover {
//       color: #00AAFF;
//     }
//   }
// `;
