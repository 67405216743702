import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";
import RouteWrapper from "./components/RouteWrapper";

import AccountConfirmation from "./components/Actor/AccountConfirmation";
import AccountConfirmed from "./components/Actor/AccountConfirmed";
import ConfirmEmailComponent from "./components/Common/ConfirmEmailComponent";
import FailConfirmEmailComponent from "./components/Common/FailConfirmEmailComponent";

import Registration from "./components/Registration";
import CastingFilmCenterStep from "./components/Director/CastingFilmCenterStep";
import CastingAccessStep from "./components/Director/CastingAccessStep";
import CastingAssociationsStep from "./components/Director/CastingAssociationsStep";
import CastingProAccountStep from "./components/Director/CastingProAccountStep";
import CastingLimitedAccountStep from "./components/Director/CastingLimitedAccountStep";
import SuccessSignUpBizhours from "./components/Common/SuccessSignUpBizhours";
import ActorStageNameStep from "./components/Actor/SignUp/ActorStageNameStep";
import ActorFilmCenterStep from "./components/Actor/SignUp/ActorFilmCenterStep";
import ActorTrialFilmCenterStep from "./components/Actor/SignUp/Trial/Special/ActorTrialFilmCenterStep";
import ActorCodeFilmCenterStep from "components/Actor/SignUp/Trial/Code/ActorCodeFilmCenterStep";
import ActorOfferFilmCenterStep from "components/Actor/SignUp/ActorOfferFilmCenterStep";
import ActorRegistrationUpgradeTable from "./components/Actor/SignUp/ActorRegistrationUpgradeTable";
import ActorRegistrationComplete from "./components/Actor/ActorRegistrationComplete";
import ActorTrailRegistrationComplete from "./components/Actor/SignUp/Trial/ActorTrailRegistrationComplete";
import ActorCodeRegistrationComplete from "components/Actor/SignUp/Trial/Code/ActorCodeRegistrationComplete";
import ActorPaymentStep from "./components/Actor/SignUp/ActorPaymentStep";
import ActorPaymentProcessing from "./components/Actor/ActorPaymentProcessing";
import ActorPaymentDeclined from "./components/Actor/ActorPaymentDeclined";
import ActorUpgradeSpecial from "./components/Actor/Upgrade/ActorUpgradeSpecial";
import ActorUpgradeTable from "./components/Actor/Upgrade/ActorUpgradeTable";
import ActorUpgradePayment from "./components/Actor/Upgrade/ActorUpgradePayment";
import ActorUpgradeComplete from "./components/Actor/Upgrade/ActorUpgradeComplete";
import ActorUpgradeProductComplete from "components/Actor/Upgrade/ActorUpgradeProductComplete";
import StripeActorPaymentWrapper from "./components/Actor/StripeActorPaymentWrapper";
import ActorTrialDetail from "./components/Actor/SignUp/Trial/ActorTrialDetail";
import TrialActorApp from "./components/Actor/SignUp/Trial/TrailActorApp";

import ActorCreateVerifyStep from "components/Actor/SignUp/ActorCreateVerifyStep";

import AcademyUserTypeSelect from "./components/Academy/UserTypeSelect";

import UserTypeSelect from "./components/CWBLabs/UserTypeSelect";
import ActorSignUp from "./components/CWBLabs/ActorSignUp";
import ActorTrialSignUp from "./components/Actor/SignUp/Trial/Special/ActorTrialSignUp";
import ActorCodeSignUp from "./components/Actor/SignUp/Trial/Code/ActorCodeSignUp";
import PerformerSignUp from "./components/CWBLabs/PerformerSignUp";
import SignUpSuccess from "./components/CWBLabs/SignUpSuccess";
import SignUpFailure from "./components/CWBLabs/SignUpFailure";
import ActorPaymentDetails from "./components/Actor/ActorPaymentDetails";
import BridgeAuthRoute from "./components/BridgeAuthRoute";
import SeamlessAuthRoute from "./components/SeamlessAuthRoute";
import OfferNotValid from "components/Common/OfferNotValid";
import ActorVerifyPhoneStepHOC from "components/Actor/SignUp/ActorVerifyPhoneStepHOC";
import ActorPaymentRequiresAction from "components/Actor/ActorPaymentRequiresAction";
import RegistrationSimple from 'components/RegistrationSimple';

const Routes: React.FC = () => {
  return (
    <>
      <ScrollToTop />
      <Switch>
        <BridgeAuthRoute
          path="/actor/payment/details"
          component={ActorPaymentDetails}
        />
        <Route
          exact
          path="/"
          render={(routeProps) => (
            <Redirect
              to={{
                pathname: "/register",
                search: routeProps.location.search,
              }}
            />
          )}
        />
        <RouteWrapper
          exact
          path="/register/offer-not-valid"
          render={(routerProps) => <OfferNotValid {...routerProps} />}
        />
        <RouteWrapper exact path="/register" component={Registration} />
        <RouteWrapper exact path="/actor" component={RegistrationSimple} />
        <Route exact path="/register/performer" component={PerformerSignUp} />
        <RouteWrapper path="/register/cwblabs/actor" component={ActorSignUp} />
        <RouteWrapper path="/register/special/actor" component={ActorTrialSignUp} />
        <RouteWrapper path="/email/confirmed"  render={(routeProps) => (<ConfirmEmailComponent {...routeProps} disableSignIn={true} />)}/>
        <RouteWrapper path="/register/trial/actor" component={ActorCodeSignUp} />
        <RouteWrapper path="/register/:accountType?" component={Registration} />
        <Route
          path="/academy/select-account-type"
          component={AcademyUserTypeSelect}
        />
        <Route path="/select-account-type" component={UserTypeSelect} />
        <RouteWrapper path="/casting/1" component={CastingFilmCenterStep} />
        <RouteWrapper
          path="/casting/2"
          render={(routeProps) => <CastingAccessStep {...routeProps} />}
        />
        <RouteWrapper
          path="/casting/select-associations"
          component={CastingAssociationsStep}
        />
        <RouteWrapper path="/casting/pro" component={CastingProAccountStep} />
        <RouteWrapper
          path="/casting/limited"
          component={CastingLimitedAccountStep}
        />
        <RouteWrapper
          isCompleted
          path="/agent/completed"
          render={(routeProps) => <SuccessSignUpBizhours {...routeProps} />}
        />
        <RouteWrapper path="/actor/1" component={ActorCreateVerifyStep} />
        <RouteWrapper path="/actor/2" component={ActorVerifyPhoneStepHOC} />
        <RouteWrapper path="/actor/3" component={ActorStageNameStep} />
        <RouteWrapper path="/actor/4" component={ActorFilmCenterStep} />
        <RouteWrapper
          path="/actor/special/4"
          component={ActorTrialFilmCenterStep}
        />
        <RouteWrapper
          path="/actor/trial/4"
          component={ActorCodeFilmCenterStep}
        />
        <RouteWrapper
          path="/actor/offer/4"
          component={ActorOfferFilmCenterStep}
        />
        <RouteWrapper
          path="/actor/5"
          component={ActorRegistrationUpgradeTable}
        />
        <RouteWrapper path="/actor/trial-detail" component={ActorTrialDetail} />
        <RouteWrapper
          isCompleted
          path="/actor/free-trial/completed"
          render={(routerProps) => (
            <ActorTrailRegistrationComplete {...routerProps} />
          )}
        />
        <RouteWrapper
          path="/actor/service-code/completed"
          isCompleted
          render={(routerProps) => (
            <ActorCodeRegistrationComplete {...routerProps} />
          )}
        />
        <RouteWrapper
          path="/trial-actor-app"
          render={(routerProps) => <TrialActorApp {...routerProps} />}
        />
        <RouteWrapper
          path="/actor/free-completed"
          isCompleted
          render={(routerProps) => (
            <ActorRegistrationComplete free {...routerProps} />
          )}
        />
        <RouteWrapper
          path="/actor/payment"
          render={(routerProps) => (
            <StripeActorPaymentWrapper>
              <ActorPaymentStep {...routerProps} />
            </StripeActorPaymentWrapper>
          )}
        />
        <RouteWrapper
          path="/actor/payment-processing"
          component={ActorPaymentProcessing}
        />
        <RouteWrapper
          path="/actor/payment-action"
          component={ActorPaymentRequiresAction}
        />
        <RouteWrapper
          path="/actor/payment-declined"
          component={ActorPaymentDeclined}
        />
        <RouteWrapper
          isCompleted
          path="/actor/pro-completed"
          component={ActorRegistrationComplete}
        />
        <RouteWrapper
          isCompleted
          path="/actor/pro-renewed"
          render={(routerProps:any) => <ActorRegistrationComplete {...routerProps} isRenewed={true} />}
        />
        <RouteWrapper
          isCompleted
          path="/actor/completed"
          render={(routerProps) => <SuccessSignUpBizhours {...routerProps} />}
        />
        <SeamlessAuthRoute
          isUpgrade
          path="/actor/upgrade-special"
          component={ActorUpgradeSpecial}
        />
        <SeamlessAuthRoute
          isUpgrade
          path="/actor/upgrade-to-premium"
          component={ActorUpgradeTable}
        />
        <SeamlessAuthRoute
          isUpgrade
          path="/actor/upgrade-to-pro"
          component={ActorUpgradeTable}
        />
        <SeamlessAuthRoute
          path="/actor/select-membership"
          isUpgrade
          render={(routerProps) => (
            <StripeActorPaymentWrapper>
              <ActorUpgradePayment {...routerProps} />
            </StripeActorPaymentWrapper>
          )}
        />
        <SeamlessAuthRoute
          isUpgrade
          isCompleted
          path="/actor/upgrade-completed"
          component={ActorUpgradeComplete}
        />
        <SeamlessAuthRoute
          isUpgrade
          isCompleted
          path="/actor/homekit-completed"
          component={ActorUpgradeProductComplete}
        />
        <RouteWrapper
          path="/actor/account-confirmation"
          render={(routerProps) => <AccountConfirmation {...routerProps} />}
        />
        <RouteWrapper
          path="/actor/account-confirmed"
          render={(routerProps) => <AccountConfirmed {...routerProps} />}
        />
        <Route path="/performer/completed" component={SignUpSuccess} />
        <Route path="/performer/failed" component={SignUpFailure} />
        <RouteWrapper
          path="/account-confirmed"
          render={(routerProps) => <ConfirmEmailComponent {...routerProps} disableSignIn={false} />}
        />
        <RouteWrapper
          path="/failConfirm"
          render={(routerProps) => (
            <FailConfirmEmailComponent {...routerProps} />
          )}
        />
        <Route
          render={() => {
            window.location.replace(process.env.REACT_APP_CWB_Site);
            return null;
          }}
        />
      </Switch>
    </>
  );
};

export default Routes;
