import * as http from "../http";
import { getTrackingData } from "helpers";
import { TrackingData } from "./dtos/ITrackingDataDto";
import {
  IActorSignupResponseDto,
  IBaseSignupCredentials,
  ISignupDetails,
  ISignupResidence,
} from "./dtos/ISignupDto";

/** Step 1 - Signup the actor in memory and sends one sms code for identity check */
export const createAccountTemp = (dto: IBaseSignupCredentials) => {
  return http.post<IActorSignupResponseDto>(
    `${process.env.REACT_APP_REG_Api}/Signup/web`,
    dto
  );
};

/** Step 2 - Verify user's phone with the phone validation code sent through SMS */
export const verifyActorPhone = (sessionId: any, code: string) => {
  return http.post<IActorSignupResponseDto>(
    `${process.env.REACT_APP_REG_Api}/Signup/web/verify/phone/${sessionId}/${code}`,
    {}
  );
};

/** Step 3 - Save user name, middlename, lastname and birthdate */
export const createStageName = (sessionId: any, dto: ISignupDetails) => {
  return http.post<boolean>(
    `${process.env.REACT_APP_REG_Api}/Signup/web/stagename/${sessionId}`,
    dto
  );
};

/** Step 4 - Save actor residence */
export const createResidence = (sessionId: any, dto: ISignupResidence) => {
  return http.post<boolean>(
    `${process.env.REACT_APP_REG_Api}/Signup/web/residence/${sessionId}`,
    dto
  );
};

/** Step 5 - Create actor as a client with default plan */
export const createAccountPartial = (sessionId: any) => {
  const trackingData = getTrackingData() || {} as TrackingData;

  return http.post<IActorSignupResponseDto>(
    `${process.env.REACT_APP_REG_Api}/Signup/web/create/${sessionId}`,
    trackingData,
  );
};

/** Step 6 - Complete actor client registration */
export const createAccountComplete = (sessionId: any) => {
  return http.post<IActorSignupResponseDto>(
    `${process.env.REACT_APP_REG_Api}/Signup/web/complete/${sessionId}`,
    {}
  );
};

export const getSignupState = (dev:string = null) => {
  const qs = dev ? `?dev=${dev}` : '';
  return http.get<IActorSignupResponseDto>(
    `${process.env.REACT_APP_REG_Api}/Signup/web/state${qs}`,
    {}
  );
};