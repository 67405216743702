import {
  setCurUserSignUp,
  getSessionId,
  setUserActorSignUpId,
  getCurUserSignUp,
  getUserActorSignUpId,
  setWebSessionKey,
  cleanObjectProperties,
  setCustomCouponCode,
  setCustomProductInfo,
} from "../helpers";
import { createModel } from "@rematch/core";
import {
  IActorCredentials,
  IActorStageName,
  IActorFilmCenter,
  IActorPhone,
  IActorError,
  IActorAccountDto,
  IActorSignUpDto,
  IAccountCredentials,
  IAccountInfo,
  IAccountRequestData,
  IActorFilmCenterTrial,
  IActorEmail,
} from "../shared/api/dtos/IActorDto";
import * as ActorRegistrationApi from "../shared/api/ActorRegistration.api";
import * as SignupApi from "../shared/api/SignUp.api";
import * as ProfileManagementApi from "../shared/api/ProfileManagement.api";
import {
  IError,
  IApiResponseError,
} from "../shared/api/dtos/IApiRepsonseError";
// @ts-ignore
import { getSessionDataFromAuthToken, setUserAuthToken } from "cwb-react";
// @ts-ignore
import * as Sentry from "@sentry/react";
import { IActorSignupResponseDto, IBaseSignupCredentials, ISignupAccountDto, ISignupDetails, ISignupResidence } from "shared/api/dtos/ISignupDto";

const actorModel = createModel({
  state: {
    sessionId: "",
    isLoading: false,
    actorCredentials: {} as IBaseSignupCredentials,
    actorStageName: {} as IActorStageName,
    actorFilmCenter: {} as IActorFilmCenter,
    actorPhone: {} as IActorPhone,
    errors: {} as IActorError,
    fields: {} as any,
    accountCredentials: {} as IAccountCredentials,
    accountRequestData: {} as IAccountRequestData,
    accountConfirmed: false,
    invalidAccount: false,
    clientId: "",
    serviceCodeError: 0,
    residenceNotMatch: null,
    selectPhone: false,
    trialServicesWorkflow: null,
    customCouponCode: '',
    customProductCode: null,
    customProductServiceId: null,
    validationError: false
  },
  reducers: {
    setIsLoading: (state: any, isLoading: boolean) => ({
      ...state,
      isLoading,
    }),
    setErrors: (state: any, errors: IActorError) => ({
      ...state,
      errors,
    }),
    setActorCredentials: (state: any, actorCredentials: any) => ({
      ...state,
      actorCredentials,
    }),
    setAccountCredentials: (
      state: any,
      accountCredentials: IAccountCredentials
    ) => ({
      ...state,
      accountCredentials,
    }),
    appendAccountRequestData: (state: any, payload: any) => ({
      ...state,
      accountRequestData: {
        ...payload,
      },
    }),
    setAccountConfirmed: (state, accountConfirmed: boolean) => ({
      ...state,
      accountConfirmed,
    }),
    setInvalidAccount: (state, invalidAccount: boolean) => ({
      ...state,
      invalidAccount,
    }),
    setClientId: (state, clientId: string) => ({
      ...state,
      clientId,
    }),
    setServiceCodeError: (state, code: number) => ({
      ...state,
      serviceCodeError: code,
    }),
    setResidenceNotMatch: (state, status: boolean) => ({
      ...state,
      residenceNotMatch: status,
    }),
    setSelectPhone: (state: any, phoneSelected: boolean) => ({
      ...state,
      selectPhone: phoneSelected,
    }),
    setTrialServicesWorkflow: (state: any, trialServicesWorkflow: boolean) => ({
      ...state,
      trialServicesWorkflow: trialServicesWorkflow
    }),
    setCustomCouponCode: (state: any, couponCode: string) => ({ ...state, customCouponCode: couponCode }),
    setCustomProductInfo: (state: any, productInfo: any) => ({ ...state, customProductCode: productInfo?.productCode, customProductServiceId: productInfo?.productServiceId }),
    setValidationError: (state: any, validationError: boolean) => ({...state, validationError: validationError})
  },
  effects: (dispatch: any) => ({
    async createActorAccount(dto: IBaseSignupCredentials, state: any) {
      cleanObjectProperties(dto);
      dispatch.actorModel.setIsLoading(true);
      dispatch.actorModel.setActorCredentials(dto);

      await SignupApi.createAccountTemp(
        dto,
      ).then((resp: any) => {
        let actorDto: ISignupAccountDto = resp!.data;

        if (actorDto) {
          actorDto.actorCredentials = dto;
          dispatch.actorModel.setErrors({} as IActorError);
          setCurUserSignUp(null); // clear user in case pick diff account type and went through one step
          setCurUserSignUp(actorDto);
        }
      }).catch((resp) => {
        Sentry.captureException(resp);
        setCurUserSignUp(null);
        let apiResponseError: IApiResponseError = resp;
        if (apiResponseError.errors) {
          dispatch.actorModel.setErrors(
            getActorErrors(apiResponseError.errors)
          );
        } else if (apiResponseError.message) {
          dispatch.actorModel.setErrors({
            errorMessage: apiResponseError.message,
          });
        }
        console.log(resp);
      });
      dispatch.actorModel.setIsLoading(false);
    },
    async verifyActorPhone(code, state: any) {
      dispatch.actorModel.setIsLoading(true);

      await SignupApi.verifyActorPhone(
        getSessionId(),
        code,
      ).then((resp: any) => {
        let actorDto: IActorSignupResponseDto = resp!.data;
        if (actorDto) {
          dispatch.actorModel.setErrors({} as IActorError);
          setCurUserSignUp(actorDto);
        }
      }).catch((resp) => {
        const { status } = resp;
        if (status >= 500) Sentry.captureException(resp);
        let apiResponseError: IApiResponseError = resp;
        if (apiResponseError.errors) {
          dispatch.actorModel.setErrors(
            getActorErrors(apiResponseError.errors)
          );
        } else if (apiResponseError.message) {
          dispatch.actorModel.setErrors({
            errorMessage: apiResponseError.message,
          });
        }
        console.log(resp);
      });
      dispatch.actorModel.setIsLoading(false);
    },
    async createStageName(dto: ISignupDetails, state: any) {
      cleanObjectProperties(dto);
      dispatch.actorModel.setIsLoading(true);
      const user: ISignupAccountDto = getCurUserSignUp();
      user.actorStageName = dto;

      await SignupApi.createStageName(
        getSessionId(), dto
      ).then((resp: any) => {
        const isSuccessful = resp!.data;
        if (isSuccessful) {
          dispatch.actorModel.setErrors({} as IActorError);
          setCurUserSignUp(user);
        }
      }).catch((resp) => {
        Sentry.captureException(resp);
        // setCurUserSignUp(null);
        let apiResponseError: IApiResponseError = resp;
        if (apiResponseError.errors) {
          dispatch.actorModel.setErrors(
            getActorErrors(apiResponseError.errors)
          );
        } else if (apiResponseError.message) {
          dispatch.actorModel.setErrors({
            errorMessage: apiResponseError.message,
          });
        }
        console.log(resp);
      });
      dispatch.actorModel.setIsLoading(false);
    },
    async createActorResidence(dto: ISignupResidence, state: any) {
      cleanObjectProperties(dto);
      dispatch.actorModel.setIsLoading(true);
      const user: ISignupAccountDto = getCurUserSignUp();
      user.actorFilmCenter = dto;

      await SignupApi.createResidence(
        getSessionId(),
        dto,
      ).then((resp: any) => {
        setCurUserSignUp(user);
        const isSuccessful = resp!.data;
        if (isSuccessful) {
          dispatch.actorModel.setErrors({} as IActorError);
          setCurUserSignUp(user);
        }
      }).catch((resp: any) => {
        Sentry.captureException(resp);
        setCurUserSignUp(null);
        let apiResponseError: IApiResponseError = resp;
        if (apiResponseError.errors) {
          dispatch.actorModel.setErrors(
            getActorErrors(apiResponseError.errors)
          );
        } else if (apiResponseError.message) {
          dispatch.actorModel.setErrors({
            errorMessage: apiResponseError.message,
          });
        }
        console.log(resp);
      });
      dispatch.actorModel.setIsLoading(false);
    },
    async createActorAccountPartial() {
      dispatch.actorModel.setIsLoading(true);

      try {
        const resp = await SignupApi.createAccountPartial(
          getSessionId(),
        );
        
        let actorSignUp: IActorSignupResponseDto = resp!.data;
        if (actorSignUp) {
          dispatch.actorModel.setErrors({} as IActorError);
          const signUpId = (actorSignUp.actorSignUpId ||
            actorSignUp.clientId)!.toString();
          setUserActorSignUpId(signUpId);

          const clientId = resp!.data.clientId;
          if (clientId) {
            dispatch.actorModel.setClientId(clientId);
          }
        }
      } catch (resp) {
        if ((resp as any).status === 400) {
          dispatch.actorModel.setValidationError(true);
        } else {
          Sentry.captureException(resp);
          setUserActorSignUpId(null);
          setCurUserSignUp(null);
        }
        let apiResponseError = resp as IApiResponseError;
        if (apiResponseError.errors) {
          dispatch.actorModel.setErrors(
            getActorErrors(apiResponseError.errors)
          );
        } else if (apiResponseError.message) {
          dispatch.actorModel.setErrors({
            errorMessage: apiResponseError.message,
          });
        }
        console.log(resp);
      } finally {
        dispatch.actorModel.setIsLoading(false);
      }
    },
    async createActorAccountComplete() {
      dispatch.actorModel.setIsLoading(true);
      
      try {
        await SignupApi.createAccountComplete(
          getSessionId(),
        );
      } catch (resp) {
        if ((resp as any).status === 400) {
          dispatch.actorModel.setValidationError(true);
        } else {
          Sentry.captureException(resp);
          setUserActorSignUpId(null);
          setCurUserSignUp(null);
        }
        let apiResponseError = resp as IApiResponseError;
        if (apiResponseError.errors) {
          dispatch.actorModel.setErrors(
            getActorErrors(apiResponseError.errors)
          );
        } else if (apiResponseError.message) {
          dispatch.actorModel.setErrors({
            errorMessage: apiResponseError.message,
          });
        }
        console.log(resp);
      } finally {
        dispatch.actorModel.setIsLoading(false);
      }
    },

    async createActorPhone(dto: IActorPhone, state: any) {
      cleanObjectProperties(dto);
      dispatch.actorModel.setIsLoading(true);
      await ActorRegistrationApi.createActorPhone(getSessionId(), dto)
        .then((resp: any) => {
          dispatch.actorModel.setIsLoading(false);
          let actorDto: IActorAccountDto = resp!.data;
          if (actorDto) {
            dispatch.actorModel.setErrors({} as IActorError);
            setCurUserSignUp(actorDto);
          }
        })
        .catch((resp) => {
          Sentry.captureException(resp);
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message,
            });
          }
          dispatch.actorModel.setIsLoading(false);
          console.log(resp);
        });
    },
    async verifyActorEmail(code: any, state: any) {
      dispatch.actorModel.setIsLoading(true);
      await ActorRegistrationApi.verifyActorEmail(getSessionId(), code?.code || code, code.dev)
        .then((resp: any) => {
          dispatch.actorModel.setIsLoading(false);
          let actorDto: IActorAccountDto = resp!.data;
          if (actorDto) {
            dispatch.actorModel.setErrors({} as IActorError);
            setCurUserSignUp(actorDto);
          }
        })
        .catch((resp) => {
          const { status } = resp;
          if (status >= 500) Sentry.captureException(resp);

          dispatch.actorModel.setIsLoading(false);
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message,
            });
          }
          console.log(resp);
        });
    },
    async verifyEmailResendCode(dto: IActorEmail, state: any) {
      dispatch.actorModel.setIsLoading(true);
      await ActorRegistrationApi.verifyEmailResendCode(getSessionId(), dto, dto.dev)
        .then((resp: any) => {
          dispatch.actorModel.setIsLoading(false);
          let actorDto: IActorAccountDto = resp!.data;
          if (actorDto) {
            dispatch.actorModel.setErrors({} as IActorError);
            setCurUserSignUp(actorDto);
          }
        })
        .catch((resp) => {
          const { status } = resp;
          if (status >= 500) Sentry.captureException(resp);
          dispatch.actorModel.setIsLoading(false);
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message,
            });
          }
          console.log(resp);
        });
    },
    async createTrialSignUp(dto: IActorFilmCenter, state: any) {
      cleanObjectProperties(dto);
      dispatch.actorModel.setIsLoading(true);
      let user: IActorAccountDto = getCurUserSignUp();
      user.actorFilmCenter = dto;
      try {
        const resp = await ActorRegistrationApi.createTrialSignUp(
          getSessionId(),
          dto,
          dto.dev
        );
        setCurUserSignUp(user);
        let actorSignUp: IActorSignUpDto = resp!.data;
        if (actorSignUp) {
          dispatch.actorModel.setErrors({} as IActorError);
          const signUpId = (actorSignUp.actorSignUpId ||
            actorSignUp.clientId)!.toString();
          setUserActorSignUpId(signUpId);
          const urlParams = new URLSearchParams(window.location.search);
          const devParam = urlParams.get("dev");
          let isDev = false;
          if (devParam && devParam === "1") isDev = true;
          const resp: any = await ActorRegistrationApi.createTrialUser(
            getUserActorSignUpId(),
            isDev
          );
          const clientId = resp!.data.clientId;
          const jwtToken = resp!.data.jwt;
          if (jwtToken) {
            setUserAuthToken(jwtToken);
            let data = await getSessionDataFromAuthToken(jwtToken);
            let webSessionKey = data.WebSessionKey;
            setWebSessionKey(webSessionKey);
          }
          dispatch.actorModel.setClientId(clientId);
          dispatch.actorModel.setIsLoading(false);
        }
      } catch (resp) {
        if ((resp as any).status === 400) {
          dispatch.actorModel.setValidationError(true);
        } else {
          Sentry.captureException(resp);
          setUserActorSignUpId(null);
          setCurUserSignUp(null);
        }
        let apiResponseError = resp as IApiResponseError;
        if (apiResponseError.errors) {
          dispatch.actorModel.setErrors(
            getActorErrors(apiResponseError.errors)
          );
        } else if (apiResponseError.message) {
          dispatch.actorModel.setErrors({
            errorMessage: apiResponseError.message,
          });
        }
        console.log(resp);
      }
    },
    async createCodeTrialSignUp(dto: IActorFilmCenterTrial, state: any) {
      cleanObjectProperties(dto);
      dispatch.actorModel.setIsLoading(true);
      const user: IActorAccountDto = getCurUserSignUp();
      const serviceCode = dto.serviceCode;
      const aId = dto.aId;
      const dId = dto.dId;
      const filmDto: IActorFilmCenter = JSON.parse(
        JSON.stringify(dto, function (key, value) {
          if (key === "serviceCode" || key === "aId" || key === "dId") {
            return undefined;
          } else {
            return value;
          }
        })
      );
      user.actorFilmCenter = filmDto;
      try {
        const resp = await ActorRegistrationApi.createTrialSignUp(
          getSessionId(),
          filmDto,
          dto.dev
        );
        setCurUserSignUp(user);
        let actorSignUp: IActorSignUpDto = resp!.data;
        if (actorSignUp) {
          dispatch.actorModel.setErrors({} as IActorError);
          const signUpId = (actorSignUp.actorSignUpId ||
            actorSignUp.clientId)!.toString();
          setUserActorSignUpId(signUpId);
          const resp: any = await ActorRegistrationApi.createTrialCodeUser(
            getUserActorSignUpId(),
            serviceCode,
            aId,
            dId
          );
          const clientId = resp!.data.clientId;
          const jwtToken = resp!.data.jwt;
          if (jwtToken) {
            setUserAuthToken(jwtToken);
            let data = await getSessionDataFromAuthToken(jwtToken);
            let webSessionKey = data.WebSessionKey;
            setWebSessionKey(webSessionKey);
          }
          dispatch.actorModel.setClientId(clientId);
          dispatch.actorModel.setIsLoading(false);
        }
      } catch (resp) {
        if ((resp as any).status === 400) {
          dispatch.actorModel.setValidationError(true);
        } else {
          Sentry.captureException(resp);
          setUserActorSignUpId(null);
          setCurUserSignUp(null);
        }

        let apiResponseError = resp as IApiResponseError;
        if (apiResponseError.errors) {
          dispatch.actorModel.setErrors(
            getActorErrors(apiResponseError.errors)
          );
        } else if (apiResponseError.message) {
          dispatch.actorModel.setErrors({
            errorMessage: apiResponseError.message,
          });
        }
        console.log(resp);
      }
    },
    async saveActorSignUp(dto: IActorAccountDto, state: any) {
      cleanObjectProperties(dto);
      dispatch.actorModel.setValidationError(false);
      dispatch.actorModel.setIsLoading(true);
      await ActorRegistrationApi.createActorSignUp(getSessionId(), dto, dto.dev)
        .then((resp: any) => {
          dispatch.actorModel.setIsLoading(false);
          setCurUserSignUp(dto);
          let actorSignUp: IActorSignUpDto = resp!.data;
          if (actorSignUp) {
            dispatch.actorModel.setErrors({} as IActorError);
            setUserActorSignUpId(
              (actorSignUp.actorSignUpId || actorSignUp.clientId)!.toString()
            );
            setCustomCouponCode((actorSignUp.actorSignUpId || actorSignUp.clientId), actorSignUp?.couponCode);
            setCustomProductInfo((actorSignUp.actorSignUpId || actorSignUp.clientId), actorSignUp?.productCode, actorSignUp?.productServiceId);
            dispatch.actorModel.setCustomCouponCode(actorSignUp?.couponCode);
            dispatch.actorModel.setCustomProductInfo({ productCustomCode: actorSignUp.productCode, productServiceId: actorSignUp.productServiceId })
            dispatch.headerModel.setUserLoggedIn(true); // TODO:
          }
        })
        .catch((resp) => {
          dispatch.actorModel.setIsLoading(false);
          if (resp.status === 400) {
            dispatch.actorModel.setValidationError(true);
          } else {
            Sentry.captureException(resp);
            setUserActorSignUpId(null);
            setCurUserSignUp(null);
            dispatch.headerModel.setUserLoggedIn(false);
          }
     
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message,
            });
          }
          console.log(resp);
        });
    },
    async getAccountInfo(guid, state: any) {
      dispatch.actorModel.setIsLoading(true);
      await ActorRegistrationApi.getAccountInfo(guid)
        .then((resp: any) => {
          dispatch.actorModel.setIsLoading(false);
          let accountInfo: IAccountInfo = resp!.data;
          if (accountInfo) {
            dispatch.actorModel.setErrors({} as IActorError);
            dispatch.actorModel.setAccountCredentials({
              ...accountInfo,
              workbookId: accountInfo.userId,
            });
          }
        })
        .catch((resp) => {
          Sentry.captureException(resp);
          dispatch.actorModel.setInvalidAccount(true);
          let apiResponseError: IApiResponseError = resp;
          if (resp.response && resp.response.data) {
            dispatch.actorModel.setErrors({
              errorMessage: resp.response.data.message,
            });
          } else if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message,
            });
          }
        });
    },
    async confirmAccount({ dto, guid }: any, state: any) {
      await ActorRegistrationApi.confirmAccount(dto.password, guid)
        .then((resp: any) => {
          if (resp.status === 200) {
            ActorRegistrationApi.loginAccount(dto).then((resp: any) => {
              if (resp.status === 200 && resp.data.accessToken) {
                const webSessionKey = JSON.parse(
                  atob(resp.data.accessToken.split(".")[1])
                );
                dispatch.actorModel.setAccountConfirmed(true);
                const qs = `tk=${webSessionKey.WebSessionKey}&cid=${dto.workbookId}&sch=${dto.sch}&saud=${dto.saud}`;
                window.localStorage.setItem("qs", qs);
              }
            });
          }
        })
        .catch((resp) => {
          Sentry.captureException(resp);
          let apiResponseError: IApiResponseError = resp;
          if (resp.response && resp.response.data) {
            dispatch.actorModel.setErrors({
              errorMessage: resp.response.data.message,
            });
          } else if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message,
            });
          }
        });
    },
    async createFreemiumUser(actorSignUpId: number) {
      return await ActorRegistrationApi.createFreemiumUser(actorSignUpId)
        .then(async (resp: any) => {
          try {
            const jwtToken = resp!.data.jwt;
            setUserAuthToken(jwtToken);
            let data = await getSessionDataFromAuthToken(jwtToken);
            let webSessionKey = data.WebSessionKey;
            setWebSessionKey(webSessionKey);
          } catch (err) {
            Sentry.captureException(err);
          } finally {
            return resp;
          }
        })
        .catch((resp) => {
          Sentry.captureException(resp);
          setCurUserSignUp(null);
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message,
            });
          }
          console.log(resp);
          throw resp;
        });
    },
    async createTrialUser(actorSignUpId: number) {
      return await ActorRegistrationApi.createTrialUser(actorSignUpId)
        .then((resp: any) => resp)
        .catch((resp) => {
          Sentry.captureException(resp);
          setCurUserSignUp(null);
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message,
            });
          }
          throw resp;
        });
    },
    async updateEmail({ clientId, email }) {
      await ProfileManagementApi.updateEmail(clientId, email)
        .then(() => { })
        .catch((e) => {
          Sentry.captureException(e);
          window.location.replace(process.env.REACT_APP_CWB_500);
        });
    },
  }),
});

const getActorErrors = (apiErrors: IError[]): IActorError => {
  let errors = {} as IActorError;
  let validError = apiErrors.find(a => a.field && a.message);
  if (validError) {
    apiErrors.forEach((k: IError) => (errors[k.field] = k.message));  
  } else {
    errors.errorMessage = apiErrors[0].toString();
  }
  
  return errors;
};

export default actorModel;